.page-container{
  background-color: var(--lightgray);
  display: flex;
  flex-direction: row;
  align-content: stretch;
  min-height: 60vh;
}

.project {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}
.project a {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit
}

.listSelector{
  display: flex;
  justify-content: space-around;
  gap: 2em;
}

#btn-active{
  font-weight: 500;
  font-size: 1.2em;
  height: 2.5em;
  background-color: var(--primary-color);
  color: var(--bg-color)
}
#btn-disabled{
  background-color: #999;
  color: #fff;
  height: 2.5em;
}

.update_btn {
    cursor: pointer;
    font-size: 1rem;
    padding: 0;
    border: none;
    background-color: inherit;
    font-family: inherit;
    color: #888;
  }

.update_btn:hover {
    color: var(--primary-color);
  }
  
#btn_right{
    float: right;
  }


  .modal-desc label{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .5em;
    margin: .5em 0;
  }
  .modal-desc label > span{
    width: 10em;
  }

.shrink{
  font-size: .5rem;
  padding: .5em;
  border: orange 4px dashed;
}

.updating-alert{
  align-self: center;
  margin: auto;
  font-size: 1.1rem;
}

.tabs{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listSelector{
  width: 100%;
}

@media screen and (max-width: 400px) {
  .page-container{
    flex-direction: column;
    align-content: stretch;
  }

  .sidebar {
    width: auto;
    margin: 1em 0;
  }

  .sidebar .links {
    margin: 1em 0 1em 1em;
  }

  .sidebar .links ul {
    padding: 10px;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }

  .sidebar .links a.active {
    color: #555;
    background: #eee;
    border-radius: 20px 20px;
  }
}
