.navbar {
  width: 100%;
  padding: 1rem 2rem 0 1rem;
  background-color: var(--bg-color);
}

.navbar .username{
  position: absolute;
  top: 1em;
  text-align: center;
  width: 100vw;
  white-space: nowrap;
}

.navbar ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.navbar ul > li {
  margin-bottom: 1em;
}
.navbar ul > li:hover {
  font-weight: 600;
}
.navbar .logo {
  font-weight: bold;
  color: var(--heading-color);
}
.navbar .logo img {
  margin-right: 10px;
  width: 150px;
  margin-top: -10px;
  margin-left: 1rem;
}
.navbar a {
  color: #333;
  text-decoration: none;
  margin-right: 20px;
}

.quickContactCard {
  float: right;
}

.quickContactCard > .phone {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.quickContactCard > .phone > img {
  height: 1.5em;
  margin-right: .5em;
}

.quickContactCard > .phone > .phone-text{
  color: var(--primary-color);
  text-align: right;
  font-weight: 500;
  font-size: 1.5em;
}
.quickContactCard > .email{
  color: var(--secondary-color);
  font-weight: 500;
  text-align: right;
  font-size: .8em;
}

@media screen and (max-width: 500px) {
  .navbar .logo img {
    width: 120px;
  }
  .navbar .username{
    position: relative;
    width: unset;
    margin: 0 0 1em 0;
  }
}
@media screen and (max-width: 1200px) {
  .navbar ul {
    justify-content: space-between;
  }
}
