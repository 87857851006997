.teamposter{
    height: 40vh;
    min-height: 350px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.teamposter-backingImage {
    background-image: url('../../assets/team/wineRack.jpg');
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, 
    from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

.teamposter > h1, .teamposter > p {
    margin: 0 2rem 1rem 2rem;
    z-index: 2;
}

.managerCard {
    margin: 2em 10em;
}

.managerCard > .memberPhoto{
    width:400px;
}

.greenLine { 
    display: block;
    border: var(--primary-color) 1px solid;
    width: 100%;
}
.memberPhoto {
    background-color: var(--primary-color);
    float: right;
    object-fit: cover;
    width: 100%;
}

.mugShots{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80vw;
    margin: 2em auto;

}

.managerCard{
    display: flex;
    margin: 1em 0 4em 0;
    width: 100%;  
}

.managerCard > .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50vw;
}

.teamMemberCard{
    display: flex;
    flex-direction: column;
    margin: 1em 0;
    flex-grow: 1;
    max-width: 43%;
}


.attributes{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: space-between;
    margin: 2em auto;
    width: 80vw;
}

.attributesHeading{
    text-align: center;
    font-weight: 400;
}

.attribute {
    width: 25%;
    text-align: center;
    min-width: 30%;
}

.attribute > h4{
    font-size: 1em;
    font-weight: 500;
}

.attribute > p{
    font-size: .8em;
    font-weight: 400;
}

.circles {
    display: inline-block;
    background-color:  var(--primary-color);
    width: 5em;
    height: 5em;
    border-radius: 100%;
}

.brag{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--tertiary-color);
    padding: 10vw;
}

.bragImage{
    width: 40%;
    object-fit: cover;
}

.bragText{
    width: 50%;
    display: flex;
    gap: .5em;
    flex-direction: column;
    justify-content: space-between;
}
.bragText > h1{
    color: var(--bg-color);
}
.bragText > p{
    color: var(--bg-color);
}

.mugShots br {
    margin: .2em;
}

@media screen and (min-width: 1200px) {
    .teamMemberCard{
        max-width: 400px;
    }
    .managerCard > .memberPhoto {
        width: 500px;
    }
    .managerCard > .text {
        justify-content: center;
    }
}

@media screen and (max-width: 800px) {
.managerCard > .memberPhoto {
    width: 300px;
}
}
@media screen and (max-width: 400px) {
    .managerCard > .memberPhoto {
        width: 150px;
    }
    .teamMemberCard{
        max-width: 40%;
    }
}