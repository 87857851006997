.contactHeader {
    background-color: var(--primary-color);
    color: var(--bg-color);
    padding: 1em;
}

.contact-container{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
}

.contactInfo{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 40%;
}

.contact-cards {
    position: relative;
    top: -1em;
}

.contactCard {
    background-color: var(--bg-color);
    margin: 4em 0 1em 0;
    padding: 0 0 1em 0;
    border: 1px solid gray;
    border-radius: 3px;
    position: relative;
}

.contactCard > img{
    height: 5em;
    position: relative;
    top: -1em;
}
.contactCard > .number{
    font-weight: 600;
}

.contactForm{
    background-color: var(--bg-color);
    border: 1px solid grey;
    border-radius: 4px;
    padding: 1.5em;
    position: relative;
    top: -2em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 50%;
    
}

.contactForm label{
    margin:0;
    margin-top: .5em;
    position: absolute;
    right: 2em;
    color: lightgray;
}

@media screen and (max-width: 800px) {
    .contact-container{
        flex-direction: column;
        gap: 6em;
    } 

    .contactInfo{
        width: 100%
    }
    .contactForm{
        width: 100%
    }
}