.pdfForm{
    background-color: #FFF;
    max-width: 900px;
}

.terms{
    padding-top: 4em;
}
.terms br{
    position: relative;
    width: 100px;
    height: 10px;
    border: 2px solid black;
}

.Header{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3em;
}
.Header h1{
    font-weight: 500;
}
.Header .stamp{
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 1em 4em;
}
.Header .StampInfo{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1em;
}
.Header .KPCInfo{
    line-height: 1.5em;
}
.form-logo{
    position: relative;
    right: 0;
    height: 150px;
}
.Header .stamp .Info h5{
    margin: 0;
    line-height: 1em;
}
.Header .stamp .Info p{
    line-height: 1em;
}

.Content .address{
    font-family: "Poppins";
    font-weight: 500;
}

.Content .table .row{
    display: flex;
    justify-content: space-between;
}

.Content .table .row .numbers{
    display: flex;
    justify-content: space-between;
    gap: 3em;
}

.table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table .topRow{
    font-weight: 600;
    border-bottom: 2px solid black;
  }
  .stage{
    border-bottom: 1px solid gray;
  }
  .stageTasks{
    border-bottom: 1px solid gray;
  }

  .table th,
   .table td {
    border-bottom: 1px solid lightgrey;
    padding: 4px;
    text-align: end;
    width: 10em;
  }
  
  
  /* .table th {
  }
   */
  .table tbody tr:hover {
    background-color: #ddd;
  }
  
  .table .description {
    width: 60%;
    text-align: start;
  } 
  .table .sub-task{
    color: gray
  }
  .table .sub-task > .description{
    padding-left: 2em;
  }

  .totalRow{
    line-height: 3em;

  }

  .cursorHover{
    cursor: pointer;
  }