.mainlist-stageCard{ 
    margin: 1em 0 0 0;
    background-color: aliceblue;
    border-radius: 5px;
}

.mainlist-stageCard h3{
    margin-left: 20px;
    padding: .5em;
}

.mainlist-stageCard-header{
    padding: 1em;
    display: flex;
    align-items: center;
}

.stageCard-header-titleBar{
    width: 100%;
    display: flex;
}
.stageCard-header-titleBar > h3{
    width: 60%;
}
.stageCard-header-titleBar > .progressbar{
    width: 90%;
}


.mainlist-taskHeader {
    background-color: var(--lightgreen);
    color: var(--bg-color);
    font-weight: 400;
    display: flex;
    justify-content: space-around;
}
.mainlist-taskHeader-name{
    width: 40%;
}
.mainlist-taskHeader-subContractor{
    width: 20%;
}
.mainlist-taskHeader-cost{
    width: 15%;
}
.mainlist-taskHeader-status{
    width: 10%;
}

.mainlist-task {
    background-color: var(--palegreen);
    border-top: 2px solid white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: .5em;
}
.mainlist-task > .arrow-right {
    border-left: 1em solid var(--bg-color);
}

.mainlist-task > span > .progressbar{
    width: 100%;
    height: .5em;
    margin: 0;
}

.TaskSection{
    padding: 1.5em;
    background-color: aliceblue;
}

.TaskSectionData{
    margin: .5em;
}

.TaskSectionData-label{
    color: var(--lightgreen);
    font-weight: 500;
}
.TaskSectionData-value{
    color: grey;
    font-weight: 500;
}

@media screen and (max-width: 700px) {
    .mainlist-stageCard-header{
        padding: .5em;
    }

    .mainList-stageTasks{
        font-size: .8em;
    }
    .mainlist-task{
        padding: .8em .5em;
    }

    .stageCard-header-titleBar{
        flex-direction: column;
    }
    .stageCard-header-titleBar > h3{
        width: 100%;
    }
    .stageCard-header-titleBar > .progressbar{
        width: 95%;
        height: .5em;
    }
}