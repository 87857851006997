.project-detail-header {
  margin-top: 2em;
}

.project-detail .team-table {
  border: 1px solid #bbb;
  border-spacing: 0;
  width: 100%;
}

.project-detail .team-table th {
  background-color: #c2cf76;
  color: #555;
  text-align: left;
}

.project-detail .team-table td {
  padding:.5rem .3rem;
  color: #555;
}

.sticky-bottom {
  position: sticky;
  bottom: .5em;
  width: 100%;
  background-color: var(--lightgray);
  padding: .5em;
  display: flex;
  justify-content: space-around;
}

.sticky-bottom .btn-white {
  width: 80%
}