@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap'); */

/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --primary-color: #768423;
  --secondary-color: rgb(84, 93, 30);
  --tertiary-color: #918776;
  --highlight-color: #e71a5e;
  --bg-color: #ffffff;
  --lightgray: rgb(231, 231, 231);
  --lightgreen: rgb(137, 172, 39);
  --palegreen: rgb(213,240,137);
}

/* base styles */
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: #ffffff;
  background: var(--bg-color);
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
  color: #444;
  color: var(--heading-color);
  font-weight: 300;
}
ul {
  list-style-type: none;
}

a{
  text-decoration: none;
}

/* layout */
.page-title {
  font-size: 2.3em;
  font-weight: 300;
  color: #444;
  color: var(--heading-color);
  display: inline-block;
}
.btn-white {
  background-color: #ffffff;
  background-color: var(--bg-color);
  color: #768423;
  color: var(--primary-color);
  padding: 7px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  box-shadow: 5px 5px 5px #444;
  box-shadow: 5px 5px 5px var(--heading-color);
  text-align: center;
  text-decoration: none;
}
.btn-green {
  background-color: #768423;
  background-color: var(--primary-color);
  color: #fff;
  padding: 7px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  border: 1px solid #768423;
  border: 1px solid var(--primary-color);
  box-shadow: 5px 5px 5px #444;
  box-shadow: 5px 5px 5px var(--heading-color);
  padding: .2em 1.5em;
  align-self: center;
  font-weight: 600;
  margin-top: .5em;
}

.btn-greenText{
  color: #768423;
  color: var(--primary-color);
  padding: 2px 5px;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid #768423;
  border: 2px solid var(--primary-color);
  align-self: center;
}
.btn-greenText:hover{
font-weight: 550;
}

.delete{
  margin-left: 3em;
  color: #e71a5e;
  color: var(--highlight-color);
  border: #e71a5e 2px solid;
  border: var(--highlight-color) 2px solid;
  border-radius: 5px;
  padding: .1em;
  cursor: pointer;
}

.delete:hover{
color: #e71a5e;
color: var(--highlight-color);
font-weight: 550;
}


.btn-red {
  background-color: #e71a5e;
  background-color: var(--highlight-color);
  color: #fff;
  padding: 5px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  border: 1px solid #768423;
  border: 1px solid var(--primary-color);
  box-shadow: 5px 5px 5px #444;
  box-shadow: 5px 5px 5px var(--heading-color);
  align-self: center;
}

.btn {
  background-color: #768423;
  background-color: var(--primary-color);
  color: #fff;
  padding: 5px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  border: 1px solid #768423;
  border: 1px solid var(--primary-color);
  align-self: center;
}

.btn:hover {
  color: #768423;
  color: var(--primary-color);
  background: inherit;
  border: .9px solid #768423;
  border: .9px solid var(--primary-color);
  font-weight: 600;
}
.btn-white:hover {
  color: #ffffff;
  color: var(--bg-color);
  background: inherit;
  font-weight: 700;
  box-shadow: 0px 0px 5px 5px #444;
  box-shadow: 0px 0px 5px 5px var(--heading-color);
}
.btn-green:hover {
  color: #768423;
  color: var(--primary-color);
  background: inherit;
  font-weight: 700;
  box-shadow: 0px 0px 5px 5px #444;
  box-shadow: 0px 0px 5px 5px var(--heading-color);
}
.btn-red:hover {
  color: #e71a5e;
  color: var(--highlight-color);
  background: inherit;
  font-weight: 900;
  box-shadow: 0px 0px 5px 5px #444;
  box-shadow: 0px 0px 5px 5px var(--heading-color);
}

.btn-cancel {
  color: #fff;
  background-color: #888;
  padding: 5px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  border: .9px solid #888;
}
.btn-cancel:hover {
  color: #888;
  background: inherit;
  border: .9px solid #888;
}

/* color */
#pc-Green {
  color: #768423;
  color: var(--primary-color);
}

/* table */
table {
  background-color: #fff;
  border-radius: 3px;
}
th {
  font-weight: 600;
}

/* forms */
label {
  display: block;
  margin: 24px auto;
}
label span {
  display: block;
  margin-bottom: 6px;
}
input, textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
textarea {
  min-height: 160px;
}
.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.flex{
  display: flex;
  grid-gap: 1em;
  gap: 1em
}

.flex-spaceBetween{
display: flex;
justify-content: space-between;
width: 100%;
}

/* Interactive */
.ch {
  cursor: pointer;
}
.App{
  font-size: 16;
  min-height: 100vh;
}

.App > .container{
  padding: 0;
  background-color: #eee;
}

.content-container {
  display: block;
  margin: 0 1em 1em 1em;
  border-radius: 5px;
  width: 95%;
}

img{
  object-fit: cover;
}

@media screen and (max-width: 1000px) {
  .App{
    font-size: 14px;
  }
}

@media screen and (max-width: 700px) {
  .App{
    font-size: 12px;
  }
}



.progressbar {
  background-color: rgb(196, 196, 196);
  border-radius: 8px;
  width: 50%;
  height: 15px;
  margin: auto 1em;
  position: relative;
}

.progress-initial {
    border-radius: 8px;
    background-color: rgb(82, 82, 173);
    float: left;
    width: 0%;
    height: 100%;
    position: absolute;
  }

  .progress-warning {
    border-radius: 8px;
    border-bottom: 2px solid black;
    background-color: rgb(255, 205, 41);
    float: left;
    width: 0%;
    height: 80%;
    position: absolute;
  }

  .progress-progress {
    border-radius:8px;
    background-color:var(--primary-color);
    float: left;
    width: 0%;
    height: 100%;
    position: absolute;
  }
.project-summary {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.projectListCard{
  background-color: var(--bg-color);
  display: flex;
  padding: .5em;
  margin-top: 1em;
  border-radius: 5px;
  justify-content: space-between;
}

.cardMainList{
  background-color: var(--bg-color);
  border-radius: 8px;
  margin-top: .5em;
}

.project-summary a {
  text-decoration: none;
  flex-grow: 2;
}
.project-summary h4 {
  font-size: 0.9em;
  color: var(--heading-color);
}
.project-summary h4:hover {
  font-weight: 550;
}
.project-summary p {
  color: var(--text-color);
  font-size: 0.9em;
}
.project-summary .assigned-to {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.project-summary ul {
  margin: 10px 0;
  display: flex;
}
.project-summary li {
  margin-right: 10px;
}
.projectListCard > .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 40%;
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: .6em solid transparent;
  border-right: .6em solid transparent;
  
  border-top: 1em solid rgb(190, 216, 190);
}

.arrow-right {
  display: inline-block;
  width: 0; 
  height: 0; 
  border-top: .6em solid transparent;
  border-bottom: .6em solid transparent;
  
  border-left: 1em solid green;
}

.cardStage-stageHeader{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--primary-color);
}
.cardStage-stageHeader > .progressbar{
  height: 10px;
  width: 35%;
}

.cardStage-stageName{
  font-weight: 500;
  margin-left: 1.2em;
}
.cardStageTasks{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--bg-color);
  background-color: var(--lightgray);
}

.cardStagetask-name{
  width: 40%;
}
.cardStagetask-status{
  width: 10%;
}

.cardStageTasks > .progressbar{
  height: 8px;
  width: 30%;
}

@media screen and (max-width: 700px) {
  .projectListCard > .progressbar {
    width: 40%;
    height: 15px;
    margin: auto .5em;
    position: relative;
  }
}

.sidebar {
  display: block;
  width: 12em;
  background: var(--primary-color);
  color: #fff;
}
.sidebar-content {
  position: fixed;
  width: inherit;
}
.sidebar .links {
  margin-top: 80px;
  margin-left: 20px;
}
.sidebar .links li {
  margin-top: 10px;
}
.sidebar .links ul {
  display: flex;
  flex-direction: column;
  color: #fff;
}
.sidebar .links a {
  display: inline-block;
  width: 100%;
  padding: 10px;
  text-decoration: none;
  color: #fff;
}
.sidebar .links img {
  margin-right: 10px;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
.sidebar .links a.active {
  color: #555;
  background: var(--lightgray);
  border-radius: 20px 0 0 20px;
}


.page-title{
  color: var(--primary-color);
}

.create-form {
  max-width: 800px;
  background-color: #fff;
  border-radius: 5px;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.create-form > h2{
  align-self: center;
}
.create-form h3{
  text-align: center;
  margin-top: 2em;
}

.create-form label{
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: .5em;
  gap: .5em;
  margin: .5em 0;
}

.create-form label > span{
  width: 30%;
}
.create-form label > div{
  width: 100%;
}

textarea{
  width: 100%;
  min-height: 8em;
}

.create-form span {
  margin: 0; 
}

.content-section > label{
  margin-top: 1em;
}

.add-btn{
  font-size: 1.5em;
  margin: 1em; 
}

.align-btn{
  text-align: center;
}

.assigned-staff{
  display: flex;
  flex-direction: column;
}

.assigned-staff > form > label{
  margin:  .5em 0;
  padding: 0 10% 0 7%;
}

.assigned-staff .btn{
  font-weight: 600;
  font-size: 1em;
}

.staffTable{
  width: 100%;
}


.staffTable > tr > th{
  text-align: left;
  
}

.staffMember{
  border: 1px solid lightgray;
  border-radius: 5px;
}
.staffMember > p, .staffMember > span{
  margin-left: .2em;
}

@media screen and (max-width: 700px) {
  .create-form {
    padding: 1em;
  }
}


.auth-form {
  max-width: 380px;
  margin: 60px auto;
  padding: 40px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
  background: #fff;
}
.page-title > strong{
    color: var(--heading-color);
    font-weight: 300;
}

.clientInfoLabel{ 
    color: var(--primary-color);
}

.clientInfoValue{ 
    font-weight: 350;
}

.project-client-info > div {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    grid-gap: .5em 2em;
    gap: .5em 2em;
}
.address-line div {
    width: 100%;
}

.project-updateClient-info{
    float: right;
}

.address-line{
    width: 100%;
}
.project-detail-header {
  margin-top: 2em;
}

.project-detail .team-table {
  border: 1px solid #bbb;
  border-spacing: 0;
  width: 100%;
}

.project-detail .team-table th {
  background-color: #c2cf76;
  color: #555;
  text-align: left;
}

.project-detail .team-table td {
  padding:.5rem .3rem;
  color: #555;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: .5em;
  width: 100%;
  background-color: var(--lightgray);
  padding: .5em;
  display: flex;
  justify-content: space-around;
}

.sticky-bottom .btn-white {
  width: 80%
}
.labourStageCard{
    margin-bottom: 1em;
}

.labourList-StageTask {
    background-color: #fff;

}

.stage-container {
    background-color: var(--lightgreen);
    color: var(--bg-color);
    padding: .6rem 0 .6rem .6rem;
    display: flex;
    flex-direction: row;
    width:100%;
    border-radius: 5px 5px 0 0;
}

.stage-name-container {
    font-size: 1.1rem;
    width: 60%;

}

.stage-role-container {
    font-size: .8rem;
    float: right;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 40%;
    align-items: center;
}

.labourList-StageTask{
    font-size: .8em;
    padding: .3em 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.lineSeperator{
    width: 100%;
    background-color: #fff;
   
}
.lineSeperator > div{
    display: block;
    border-bottom: 1px solid var(--palegreen);
    margin: 0 3px;
}

.labourList-StageSum{
    background-color: var(--palegreen);
    border-bottom: 2px solid #fff;
    position: relative;
    top: -1px;
}

.labourList-StageTotal{
    background-color: #fff;
    position: relative;
    top: -1px;
    border-radius: 0 0 5px 5px;
    font-weight: 500;
}
.labourList-StageTotal > span{
    width: 33%;
    text-align: right;
}


.labourList-StageTask > .task-container{
    width: 60%;

}

.labourList-StageTask > .hours-container{
    width: 40%;
    justify-content: flex-end;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
}

.hours-container > form {
    display: flex;
    width: 100%;
}

.single-hour-container{
    text-align: center;
    width:10%;
    margin: 0px;
    padding: 0px;
}
.single-hour-container > input{
    margin: 0px;
    padding: 0px;
}


@media screen and (max-width: 900px) {

    .stage-role-container > span {
        font-size: .7em;
    }
}
.project-labour-list{
  border-radius: 5px;
}

.project-labour-list .task-group-container{
  margin: 1rem;  
  border-radius: inherit;
}

.project-labour-list .task-group-name-container {
  background-color:rgb(187, 198, 106);
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}


/* ////////////// ProjectClientInfoModel.css ///////////////// */
.modal {
  font-family: inherit;
  position: fixed;
  width: 95%;
  max-width: 750px;
  z-index: 1040;
  top: 1em;
  left: 1em;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  overflow: visible;
}

.backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
}

.modal-title {
  font-weight: 500;
  font-size: 1.25rem;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
}

.modal-desc,
.modal-header {
  padding: 1rem 1.3rem;
}

.calculatedAmount{
  color: green;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.close-button {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #888;
  border: none;
  background-color: white;
  opacity: 0.7;
  cursor: pointer;
}

button {
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  border: none;
  background-color: inherit;
  font-family: inherit;
  color: #888;
}

button:hover {
  color: var(--primary-color);
}

#btn_right{
  float: right;
}

.modal-footer {
  border-top: 1px solid #e9ecef;
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-gap: 2em;
  gap: 2em;
}

.modal-example {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-label{
  min-width: 4em;
}

select, .inputSelector{
  padding: .5em;
  width: 100%;
  font-size: 1em;
  color: var(--heading-color);
  background-color: var;
}
@media screen and (max-width: 700px) {
  .modal {
    width: 90%; 
  }
  .modal-footer {
    justify-content: space-between;
    grid-gap: 2em;
    gap: 2em;
  }
}

.project-financial-info {
  width: 100%;
  background-color: #ddd;
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 5px;
  padding: 1em;
}

.financialData {
  display: flex;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
}

.financialData > .row-value{
  font-weight: 300;
  width: 7em;
  text-align: right;
}



@media screen and (max-width: 700px) {
  .project-financial-info {
    flex-direction: column;
    font-size: 1rem;
  }
  .financialData{
    width: 100%;
  }
}


.page-container{
  background-color: var(--lightgray);
  display: flex;
  flex-direction: row;
  align-content: stretch;
  min-height: 60vh;
}

.project {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}
.project a {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit
}

.listSelector{
  display: flex;
  justify-content: space-around;
  grid-gap: 2em;
  gap: 2em;
}

#btn-active{
  font-weight: 500;
  font-size: 1.2em;
  height: 2.5em;
  background-color: var(--primary-color);
  color: var(--bg-color)
}
#btn-disabled{
  background-color: #999;
  color: #fff;
  height: 2.5em;
}

.update_btn {
    cursor: pointer;
    font-size: 1rem;
    padding: 0;
    border: none;
    background-color: inherit;
    font-family: inherit;
    color: #888;
  }

.update_btn:hover {
    color: var(--primary-color);
  }
  
#btn_right{
    float: right;
  }


  .modal-desc label{
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: .5em;
    gap: .5em;
    margin: .5em 0;
  }
  .modal-desc label > span{
    width: 10em;
  }

.shrink{
  font-size: .5rem;
  padding: .5em;
  border: orange 4px dashed;
}

.updating-alert{
  align-self: center;
  margin: auto;
  font-size: 1.1rem;
}

.tabs{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listSelector{
  width: 100%;
}

@media screen and (max-width: 400px) {
  .page-container{
    flex-direction: column;
    align-content: stretch;
  }

  .sidebar {
    width: auto;
    margin: 1em 0;
  }

  .sidebar .links {
    margin: 1em 0 1em 1em;
  }

  .sidebar .links ul {
    padding: 10px;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
  }

  .sidebar .links a.active {
    color: #555;
    background: #eee;
    border-radius: 20px 20px;
  }
}

.claimCard{
    max-width: 700px;
    margin-bottom: 2em;
    background-color: var(--bg-color);
    border-radius: 5px;
    padding: 1em;
}

.claimCard-row{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary-color);
}
.claimCard-row > .taskTotal{
   color: grey;
}

.row-name{
 width: 35%;
}
.row-value{
width: 20%;
max-width: 6em;
}
.row-calculatedamount{
width: 25%
}

.claim-count, .claim-total{
    font-size: 1.2em;
    font-weight: 550;
}

.claim-date{
    font-weight:450;
}

.claim-description{
    display: flex;
    grid-gap: 2em;
    gap: 2em;
}

.claim-description .label{
    font-weight: 450;
}
.nextClaim{
    background-color: var(--palegreen);
    border-radius: 5px;
    padding: 1em;
    margin-bottom: 2em;
    width: 700px;
    max-width: 85vw;
}

.nextClaim-header{
    margin-bottom: 1em;
}

.nextClaim-row{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--bg-color);
}

.nextClaim-input{
    display: flex;
    justify-content: space-between;
}

.nextClaim-comment{
    padding: 0;
    height: 2em;
    width: 80%;
}

.mainlist-stageCard{ 
    margin: 1em 0 0 0;
    background-color: aliceblue;
    border-radius: 5px;
}

.mainlist-stageCard h3{
    margin-left: 20px;
    padding: .5em;
}

.mainlist-stageCard-header{
    padding: 1em;
    display: flex;
    align-items: center;
}

.stageCard-header-titleBar{
    width: 100%;
    display: flex;
}
.stageCard-header-titleBar > h3{
    width: 60%;
}
.stageCard-header-titleBar > .progressbar{
    width: 90%;
}


.mainlist-taskHeader {
    background-color: var(--lightgreen);
    color: var(--bg-color);
    font-weight: 400;
    display: flex;
    justify-content: space-around;
}
.mainlist-taskHeader-name{
    width: 40%;
}
.mainlist-taskHeader-subContractor{
    width: 20%;
}
.mainlist-taskHeader-cost{
    width: 15%;
}
.mainlist-taskHeader-status{
    width: 10%;
}

.mainlist-task {
    background-color: var(--palegreen);
    border-top: 2px solid white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: .5em;
}
.mainlist-task > .arrow-right {
    border-left: 1em solid var(--bg-color);
}

.mainlist-task > span > .progressbar{
    width: 100%;
    height: .5em;
    margin: 0;
}

.TaskSection{
    padding: 1.5em;
    background-color: aliceblue;
}

.TaskSectionData{
    margin: .5em;
}

.TaskSectionData-label{
    color: var(--lightgreen);
    font-weight: 500;
}
.TaskSectionData-value{
    color: grey;
    font-weight: 500;
}

@media screen and (max-width: 700px) {
    .mainlist-stageCard-header{
        padding: .5em;
    }

    .mainList-stageTasks{
        font-size: .8em;
    }
    .mainlist-task{
        padding: .8em .5em;
    }

    .stageCard-header-titleBar{
        flex-direction: column;
    }
    .stageCard-header-titleBar > h3{
        width: 100%;
    }
    .stageCard-header-titleBar > .progressbar{
        width: 95%;
        height: .5em;
    }
}
.space{
    display: flex;
    grid-gap: 1em;
    gap: 1em;
    width: 15%;
    flex-wrap: wrap;
}

.updateStage-footer{
    display: flex;
    justify-content: space-around;
    width: 350px
}

.mainlist-stageTotals{
    display: flex;
    justify-content: space-around;
}

.numerator{
    color: var(--primary-color);
    font-size: .9em;
}

.mainlist-nextClaim{
    color: var(--secondary-color);
    font-weight: 500;
    font-size: 1.1em;
}

@media screen and (max-width: 700px) {
    
    .updateStage-footer{
        margin: 0;
    }
    .updateStage-footer > button{
        margin: 0;
        font-size: .8em;
    }
}

.pdfForm{
    background-color: #FFF;
    max-width: 900px;
}

.terms{
    padding-top: 4em;
}
.terms br{
    position: relative;
    width: 100px;
    height: 10px;
    border: 2px solid black;
}

.Header{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 3em;
    gap: 3em;
}
.Header h1{
    font-weight: 500;
}
.Header .stamp{
    display: flex;
    flex-wrap: wrap-reverse;
    grid-gap: 1em 4em;
    gap: 1em 4em;
}
.Header .StampInfo{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    grid-gap: 1em;
    gap: 1em;
}
.Header .KPCInfo{
    line-height: 1.5em;
}
.form-logo{
    position: relative;
    right: 0;
    height: 150px;
}
.Header .stamp .Info h5{
    margin: 0;
    line-height: 1em;
}
.Header .stamp .Info p{
    line-height: 1em;
}

.Content .address{
    font-family: "Poppins";
    font-weight: 500;
}

.Content .table .row{
    display: flex;
    justify-content: space-between;
}

.Content .table .row .numbers{
    display: flex;
    justify-content: space-between;
    grid-gap: 3em;
    gap: 3em;
}

.table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table .topRow{
    font-weight: 600;
    border-bottom: 2px solid black;
  }
  .stage{
    border-bottom: 1px solid gray;
  }
  .stageTasks{
    border-bottom: 1px solid gray;
  }

  .table th,
   .table td {
    border-bottom: 1px solid lightgrey;
    padding: 4px;
    text-align: end;
    width: 10em;
  }
  
  
  /* .table th {
  }
   */
  .table tbody tr:hover {
    background-color: #ddd;
  }
  
  .table .description {
    width: 60%;
    text-align: start;
  } 
  .table .sub-task{
    color: gray
  }
  .table .sub-task > .description{
    padding-left: 2em;
  }

  .totalRow{
    line-height: 3em;

  }

  .cursorHover{
    cursor: pointer;
  }
.formText {
    display: flex;
    justify-content: space-between;
    color: black
    }
    .formText input {
        background-color: #ec67671a;
        color: inherit
    }
.navbar {
  width: 100%;
  padding: 1rem 2rem 0 1rem;
  background-color: var(--bg-color);
}

.navbar .username{
  position: absolute;
  top: 1em;
  text-align: center;
  width: 100vw;
  white-space: nowrap;
}

.navbar ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.navbar ul > li {
  margin-bottom: 1em;
}
.navbar ul > li:hover {
  font-weight: 600;
}
.navbar .logo {
  font-weight: bold;
  color: var(--heading-color);
}
.navbar .logo img {
  margin-right: 10px;
  width: 150px;
  margin-top: -10px;
  margin-left: 1rem;
}
.navbar a {
  color: #333;
  text-decoration: none;
  margin-right: 20px;
}

.quickContactCard {
  float: right;
}

.quickContactCard > .phone {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.quickContactCard > .phone > img {
  height: 1.5em;
  margin-right: .5em;
}

.quickContactCard > .phone > .phone-text{
  color: var(--primary-color);
  text-align: right;
  font-weight: 500;
  font-size: 1.5em;
}
.quickContactCard > .email{
  color: var(--secondary-color);
  font-weight: 500;
  text-align: right;
  font-size: .8em;
}

@media screen and (max-width: 500px) {
  .navbar .logo img {
    width: 120px;
  }
  .navbar .username{
    position: relative;
    width: unset;
    margin: 0 0 1em 0;
  }
}
@media screen and (max-width: 1200px) {
  .navbar ul {
    justify-content: space-between;
  }
}

.poster {
    width: 100%;
    min-height: 40vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.backingImage {
    background-image: url(/static/media/service_construction.ed8ab191.JPG);
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-filter: brightness(40%);
            filter: brightness(40%);
    background-color: var(--primary-color);
}

.backingTint {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--highlight-color);
    opacity:14%;
}

.poster-header {
    position: relative;
    float: right;
    margin: 1em 1.5em;
    color: var(--bg-color);
    font-weight: 500;
    font-size: 2em;
    text-align: right;
}

.poster-text {
    margin: 0 40% 3em 2em;
    color: var(--bg-color);
    height: 100%;
    position: relative;
    
}

.services{
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1.5em 0;
    text-align: left;
}

.service{
    margin: 2em 0;
    width: 43%
}

.service > h2 {
    padding-top: .5em;
}

.service > p {
    color: var(--text-color);
}

.service-image {
    display: block;
    width: 100%;
    height: 17rem;
    object-fit: cover;
    background-color: var(--primary-color);
}

.pad {
    padding: 30px;
    text-align: center;
}

.pad > h1 {
    padding-top: 8vh;
}

.testimonial {
    background-color: var(--secondary-color);
    padding: 2em 30px;
    display: flex;
    justify-content: space-between;

}
.testimonial-text {
    margin-right: 1.5em;
    width: 40vw;
}

.testimonial-text > h2, .testimonial-text > h3, .testimonial-text > p{
    color: var(--bg-color);
    margin: .5em 0;
}

.testimonial-image {
    background-color: var(--primary-color);
    width: 40vw;
}

.sustainability {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    position: relative;
}

.sustainability-text {
    margin: 0;
    padding: 5vh;
    color: var(--bg-color);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.sustainability-text > h1, .sustainability-text > h2{
    font-weight: 400;
    margin: .5em;
}

.sustainability-text > .btn-green{
    min-width: 15em;
}

.sustainabilityImage {
    background-image: url(/static/media/kauri-forest.76a37791.jpg);
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 30%;
}



/* @media screen and (max-width: 1200px) {
    .testimonial-image {
        height: 90%;
        width: 500px;
    }
} */


@media screen and (max-width: 700px) {
    .testimonial{
        flex-direction: column;
    }
    .services{
        padding: 1.5em 0;
    }
    .service{
        width: 100%;
    }
    .btn-green{
        width: 60%;
    }
    .testimonial-text{
        width: 100%;
    } 
    .testimonial-image{
        width: 100%;
    } 
}
.teamposter{
    height: 40vh;
    min-height: 350px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.teamposter-backingImage {
    background-image: url(/static/media/wineRack.d4c9e501.jpg);
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, 
    from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

.teamposter > h1, .teamposter > p {
    margin: 0 2rem 1rem 2rem;
    z-index: 2;
}

.managerCard {
    margin: 2em 10em;
}

.managerCard > .memberPhoto{
    width:400px;
}

.greenLine { 
    display: block;
    border: var(--primary-color) 1px solid;
    width: 100%;
}
.memberPhoto {
    background-color: var(--primary-color);
    float: right;
    object-fit: cover;
    width: 100%;
}

.mugShots{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80vw;
    margin: 2em auto;

}

.managerCard{
    display: flex;
    margin: 1em 0 4em 0;
    width: 100%;  
}

.managerCard > .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50vw;
}

.teamMemberCard{
    display: flex;
    flex-direction: column;
    margin: 1em 0;
    flex-grow: 1;
    max-width: 43%;
}


.attributes{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 2em;
    gap: 2em;
    justify-content: space-between;
    margin: 2em auto;
    width: 80vw;
}

.attributesHeading{
    text-align: center;
    font-weight: 400;
}

.attribute {
    width: 25%;
    text-align: center;
    min-width: 30%;
}

.attribute > h4{
    font-size: 1em;
    font-weight: 500;
}

.attribute > p{
    font-size: .8em;
    font-weight: 400;
}

.circles {
    display: inline-block;
    background-color:  var(--primary-color);
    width: 5em;
    height: 5em;
    border-radius: 100%;
}

.brag{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--tertiary-color);
    padding: 10vw;
}

.bragImage{
    width: 40%;
    object-fit: cover;
}

.bragText{
    width: 50%;
    display: flex;
    grid-gap: .5em;
    gap: .5em;
    flex-direction: column;
    justify-content: space-between;
}
.bragText > h1{
    color: var(--bg-color);
}
.bragText > p{
    color: var(--bg-color);
}

.mugShots br {
    margin: .2em;
}

@media screen and (min-width: 1200px) {
    .teamMemberCard{
        max-width: 400px;
    }
    .managerCard > .memberPhoto {
        width: 500px;
    }
    .managerCard > .text {
        justify-content: center;
    }
}

@media screen and (max-width: 800px) {
.managerCard > .memberPhoto {
    width: 300px;
}
}
@media screen and (max-width: 400px) {
    .managerCard > .memberPhoto {
        width: 150px;
    }
    .teamMemberCard{
        max-width: 40%;
    }
}
.showcasePoster{
    background-color: var(--tertiary-color);
    position: relative;
    height: 40vh;
    min-height: 225px;
}
.showcasePoster >.text{
    position: absolute;
    z-index: 1;
    left: 2em;
    bottom: 3vh;
    width: 70%;
}
.showcasePoster >.text > h1{
    color: var(--bg-color);
    font-weight: 400;
}
.showcasePoster > .text > p{
    color: var(--bg-color);
    width: 55%;
    font-weight: 350;
}
.showcaseImage{
    background-color: var(--primary-color);
    position: relative;
    width: 10em;
    top: 3vh;
    height: 34vh;
    min-height: 200px;
    float: right;
    width: 65vw;
    z-index: 0;
}

.completedProjects h1{
    margin: 4em 0 2em 0;
    text-align: center;
}

.projectCard{
    width: 90%;
    margin: 4em auto;
    display: flex;
    justify-content: space-between;
}

.projectCard > .text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.projectCard h2{
    color: var(--primary-color);
    margin: .5em 0;
}

.projectCard p {
    margin: .25em 0;
    font-size: 1.5em;
}

.projectCard .text > p{
    margin-right: .6em;
}

.projectCard > img{
    height: 300px;
    background-color: var(--primary-color);
    min-width: 40vw;
}

.skillsPoster{
    background-color: var(--secondary-color);
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 3em;
}
.skillsPoster > .text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.skillsPoster > .btn-white {
    margin-top: 2em;
}
.skillsPoster > .text > h1{
    color: var(--bg-color);
    font-weight: 150;
    text-align: center;
}

.skillsPoster > .skillsList > ul{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.skillsPoster > .skillsList> ul > li{
    color: var(--bg-color);
    font-size: .8em;
    font-weight: 450;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 100%;
    width: 10em;
    height: 10em;
    margin: 1em;
    text-align: center; 
    background-color: lightgrey;
    color: var(--primary-color)
}

.skillsList{
    display: flex;

}


@media screen and (max-width: 500px) {
    .projectCard{
        flex-direction: column;
    }

    .projectCard > img{
        height: 350px;
        max-width: 100vw;
    }
}

@media screen and (max-height: 500px) {


}

@media screen and (min-width:1200px) {
    .projectCard > img{
        min-width: 470px
    }
}
.footer {
    width: 100%;
    padding: 1rem;
  }
  .footer ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .footer ul > li{
    margin: .5em 2em;
  }
  .footer .logo {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .footer .logo img {
    margin: 1rem;
    height: 5rem;
  }
  .footer a {
    color: var(--secondary-color);
    text-decoration: none;
  }
  
  .footer-copyright{
    color: var(--secondary-color);
    text-align: center;
    font-size: .7em;
  }

  @media screen and (max-width: 500px) {
    .footer {
      padding: .5rem;
    }

    .footer ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
    }

    .footer ul > li{
      margin: .5em .8em;
    }
    }
.contactHeader {
    background-color: var(--primary-color);
    color: var(--bg-color);
    padding: 1em;
}

.contact-container{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
}

.contactInfo{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 40%;
}

.contact-cards {
    position: relative;
    top: -1em;
}

.contactCard {
    background-color: var(--bg-color);
    margin: 4em 0 1em 0;
    padding: 0 0 1em 0;
    border: 1px solid gray;
    border-radius: 3px;
    position: relative;
}

.contactCard > img{
    height: 5em;
    position: relative;
    top: -1em;
}
.contactCard > .number{
    font-weight: 600;
}

.contactForm{
    background-color: var(--bg-color);
    border: 1px solid grey;
    border-radius: 4px;
    padding: 1.5em;
    position: relative;
    top: -2em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 50%;
    
}

.contactForm label{
    margin:0;
    margin-top: .5em;
    position: absolute;
    right: 2em;
    color: lightgray;
}

@media screen and (max-width: 800px) {
    .contact-container{
        flex-direction: column;
        grid-gap: 6em;
        gap: 6em;
    } 

    .contactInfo{
        width: 100%
    }
    .contactForm{
        width: 100%
    }
}
.libraryNav{
    margin: 1em;
    display: flex;
    justify-content: space-around;
}

.card{
    background-color: #FFF;
    margin: 1em;
    padding: 1em;
    border-radius: 8px;
}

.card .title{
    margin-bottom: .5em;

}

.card .task{
    margin-bottom: .5em;
    border-bottom: var(--secondary-color) 1px solid;
}
