.project-summary {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.projectListCard{
  background-color: var(--bg-color);
  display: flex;
  padding: .5em;
  margin-top: 1em;
  border-radius: 5px;
  justify-content: space-between;
}

.cardMainList{
  background-color: var(--bg-color);
  border-radius: 8px;
  margin-top: .5em;
}

.project-summary a {
  text-decoration: none;
  flex-grow: 2;
}
.project-summary h4 {
  font-size: 0.9em;
  color: var(--heading-color);
}
.project-summary h4:hover {
  font-weight: 550;
}
.project-summary p {
  color: var(--text-color);
  font-size: 0.9em;
}
.project-summary .assigned-to {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.project-summary ul {
  margin: 10px 0;
  display: flex;
}
.project-summary li {
  margin-right: 10px;
}
.projectListCard > .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 40%;
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: .6em solid transparent;
  border-right: .6em solid transparent;
  
  border-top: 1em solid rgb(190, 216, 190);
}

.arrow-right {
  display: inline-block;
  width: 0; 
  height: 0; 
  border-top: .6em solid transparent;
  border-bottom: .6em solid transparent;
  
  border-left: 1em solid green;
}

.cardStage-stageHeader{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--primary-color);
}
.cardStage-stageHeader > .progressbar{
  height: 10px;
  width: 35%;
}

.cardStage-stageName{
  font-weight: 500;
  margin-left: 1.2em;
}
.cardStageTasks{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--bg-color);
  background-color: var(--lightgray);
}

.cardStagetask-name{
  width: 40%;
}
.cardStagetask-status{
  width: 10%;
}

.cardStageTasks > .progressbar{
  height: 8px;
  width: 30%;
}

@media screen and (max-width: 700px) {
  .projectListCard > .progressbar {
    width: 40%;
    height: 15px;
    margin: auto .5em;
    position: relative;
  }
}
