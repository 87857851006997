@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap'); */

/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --primary-color: #768423;
  --secondary-color: rgb(84, 93, 30);
  --tertiary-color: #918776;
  --highlight-color: #e71a5e;
  --bg-color: #ffffff;
  --lightgray: rgb(231, 231, 231);
  --lightgreen: rgb(137, 172, 39);
  --palegreen: rgb(213,240,137);
}

/* base styles */
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: var(--bg-color);
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
  color: var(--heading-color);
  font-weight: 300;
}
ul {
  list-style-type: none;
}

a{
  text-decoration: none;
}

/* layout */
.page-title {
  font-size: 2.3em;
  font-weight: 300;
  color: var(--heading-color);
  display: inline-block;
}
.btn-white {
  background-color: var(--bg-color);
  color: var(--primary-color);
  padding: 7px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  box-shadow: 5px 5px 5px var(--heading-color);
  text-align: center;
  text-decoration: none;
}
.btn-green {
  background-color: var(--primary-color);
  color: #fff;
  padding: 7px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
  box-shadow: 5px 5px 5px var(--heading-color);
  padding: .2em 1.5em;
  align-self: center;
  font-weight: 600;
  margin-top: .5em;
}

.btn-greenText{
  color: var(--primary-color);
  padding: 2px 5px;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid var(--primary-color);
  align-self: center;
}
.btn-greenText:hover{
font-weight: 550;
}

.delete{
  margin-left: 3em;
  color: var(--highlight-color);
  border: var(--highlight-color) 2px solid;
  border-radius: 5px;
  padding: .1em;
  cursor: pointer;
}

.delete:hover{
color: var(--highlight-color);
font-weight: 550;
}


.btn-red {
  background-color: var(--highlight-color);
  color: #fff;
  padding: 5px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
  box-shadow: 5px 5px 5px var(--heading-color);
  align-self: center;
}

.btn {
  background-color: var(--primary-color);
  color: #fff;
  padding: 5px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
  align-self: center;
}

.btn:hover {
  color: var(--primary-color);
  background: inherit;
  border: .9px solid var(--primary-color);
  font-weight: 600;
}
.btn-white:hover {
  color: var(--bg-color);
  background: inherit;
  font-weight: 700;
  box-shadow: 0px 0px 5px 5px var(--heading-color);
}
.btn-green:hover {
  color: var(--primary-color);
  background: inherit;
  font-weight: 700;
  box-shadow: 0px 0px 5px 5px var(--heading-color);
}
.btn-red:hover {
  color: var(--highlight-color);
  background: inherit;
  font-weight: 900;
  box-shadow: 0px 0px 5px 5px var(--heading-color);
}

.btn-cancel {
  color: #fff;
  background-color: #888;
  padding: 5px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  border: .9px solid #888;
}
.btn-cancel:hover {
  color: #888;
  background: inherit;
  border: .9px solid #888;
}

/* color */
#pc-Green {
  color: var(--primary-color);
}

/* table */
table {
  background-color: #fff;
  border-radius: 3px;
}
th {
  font-weight: 600;
}

/* forms */
label {
  display: block;
  margin: 24px auto;
}
label span {
  display: block;
  margin-bottom: 6px;
}
input, textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
textarea {
  min-height: 160px;
}
.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.flex{
  display: flex;
  gap: 1em
}

.flex-spaceBetween{
display: flex;
justify-content: space-between;
width: 100%;
}

/* Interactive */
.ch {
  cursor: pointer;
}