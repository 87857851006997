.footer {
    width: 100%;
    padding: 1rem;
  }
  .footer ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .footer ul > li{
    margin: .5em 2em;
  }
  .footer .logo {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .footer .logo img {
    margin: 1rem;
    height: 5rem;
  }
  .footer a {
    color: var(--secondary-color);
    text-decoration: none;
  }
  
  .footer-copyright{
    color: var(--secondary-color);
    text-align: center;
    font-size: .7em;
  }

  @media screen and (max-width: 500px) {
    .footer {
      padding: .5rem;
    }

    .footer ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
    }

    .footer ul > li{
      margin: .5em .8em;
    }
    }