.showcasePoster{
    background-color: var(--tertiary-color);
    position: relative;
    height: 40vh;
    min-height: 225px;
}
.showcasePoster >.text{
    position: absolute;
    z-index: 1;
    left: 2em;
    bottom: 3vh;
    width: 70%;
}
.showcasePoster >.text > h1{
    color: var(--bg-color);
    font-weight: 400;
}
.showcasePoster > .text > p{
    color: var(--bg-color);
    width: 55%;
    font-weight: 350;
}
.showcaseImage{
    background-color: var(--primary-color);
    position: relative;
    width: 10em;
    top: 3vh;
    height: 34vh;
    min-height: 200px;
    float: right;
    width: 65vw;
    z-index: 0;
}

.completedProjects h1{
    margin: 4em 0 2em 0;
    text-align: center;
}

.projectCard{
    width: 90%;
    margin: 4em auto;
    display: flex;
    justify-content: space-between;
}

.projectCard > .text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.projectCard h2{
    color: var(--primary-color);
    margin: .5em 0;
}

.projectCard p {
    margin: .25em 0;
    font-size: 1.5em;
}

.projectCard .text > p{
    margin-right: .6em;
}

.projectCard > img{
    height: 300px;
    background-color: var(--primary-color);
    min-width: 40vw;
}

.skillsPoster{
    background-color: var(--secondary-color);
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 3em;
}
.skillsPoster > .text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.skillsPoster > .btn-white {
    margin-top: 2em;
}
.skillsPoster > .text > h1{
    color: var(--bg-color);
    font-weight: 150;
    text-align: center;
}

.skillsPoster > .skillsList > ul{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.skillsPoster > .skillsList> ul > li{
    color: var(--bg-color);
    font-size: .8em;
    font-weight: 450;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 100%;
    width: 10em;
    height: 10em;
    margin: 1em;
    text-align: center; 
    background-color: lightgrey;
    color: var(--primary-color)
}

.skillsList{
    display: flex;

}


@media screen and (max-width: 500px) {
    .projectCard{
        flex-direction: column;
    }

    .projectCard > img{
        height: 350px;
        max-width: 100vw;
    }
}

@media screen and (max-height: 500px) {


}

@media screen and (min-width:1200px) {
    .projectCard > img{
        min-width: 470px
    }
}