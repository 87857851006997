.labourStageCard{
    margin-bottom: 1em;
}

.labourList-StageTask {
    background-color: #fff;

}

.stage-container {
    background-color: var(--lightgreen);
    color: var(--bg-color);
    padding: .6rem 0 .6rem .6rem;
    display: flex;
    flex-direction: row;
    width:100%;
    border-radius: 5px 5px 0 0;
}

.stage-name-container {
    font-size: 1.1rem;
    width: 60%;

}

.stage-role-container {
    font-size: .8rem;
    float: right;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 40%;
    align-items: center;
}

.labourList-StageTask{
    font-size: .8em;
    padding: .3em 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.lineSeperator{
    width: 100%;
    background-color: #fff;
   
}
.lineSeperator > div{
    display: block;
    border-bottom: 1px solid var(--palegreen);
    margin: 0 3px;
}

.labourList-StageSum{
    background-color: var(--palegreen);
    border-bottom: 2px solid #fff;
    position: relative;
    top: -1px;
}

.labourList-StageTotal{
    background-color: #fff;
    position: relative;
    top: -1px;
    border-radius: 0 0 5px 5px;
    font-weight: 500;
}
.labourList-StageTotal > span{
    width: 33%;
    text-align: right;
}


.labourList-StageTask > .task-container{
    width: 60%;

}

.labourList-StageTask > .hours-container{
    width: 40%;
    justify-content: flex-end;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
}

.hours-container > form {
    display: flex;
    width: 100%;
}

.single-hour-container{
    text-align: center;
    width:10%;
    margin: 0px;
    padding: 0px;
}
.single-hour-container > input{
    margin: 0px;
    padding: 0px;
}


@media screen and (max-width: 900px) {

    .stage-role-container > span {
        font-size: .7em;
    }
}