.sidebar {
  display: block;
  width: 12em;
  background: var(--primary-color);
  color: #fff;
}
.sidebar-content {
  position: fixed;
  width: inherit;
}
.sidebar .links {
  margin-top: 80px;
  margin-left: 20px;
}
.sidebar .links li {
  margin-top: 10px;
}
.sidebar .links ul {
  display: flex;
  flex-direction: column;
  color: #fff;
}
.sidebar .links a {
  display: inline-block;
  width: 100%;
  padding: 10px;
  text-decoration: none;
  color: #fff;
}
.sidebar .links img {
  margin-right: 10px;
  filter: invert(100%);
}
.sidebar .links a.active {
  color: #555;
  background: var(--lightgray);
  border-radius: 20px 0 0 20px;
}
