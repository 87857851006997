.App{
  font-size: 16;
  min-height: 100vh;
}

.App > .container{
  padding: 0;
  background-color: #eee;
}

.content-container {
  display: block;
  margin: 0 1em 1em 1em;
  border-radius: 5px;
  width: 95%;
}

img{
  object-fit: cover;
}

@media screen and (max-width: 1000px) {
  .App{
    font-size: 14px;
  }
}

@media screen and (max-width: 700px) {
  .App{
    font-size: 12px;
  }
}

