.claimCard{
    max-width: 700px;
    margin-bottom: 2em;
    background-color: var(--bg-color);
    border-radius: 5px;
    padding: 1em;
}

.claimCard-row{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary-color);
}
.claimCard-row > .taskTotal{
   color: grey;
}

.row-name{
 width: 35%;
}
.row-value{
width: 20%;
max-width: 6em;
}
.row-calculatedamount{
width: 25%
}

.claim-count, .claim-total{
    font-size: 1.2em;
    font-weight: 550;
}

.claim-date{
    font-weight:450;
}

.claim-description{
    display: flex;
    gap: 2em;
}

.claim-description .label{
    font-weight: 450;
}