.space{
    display: flex;
    gap: 1em;
    width: 15%;
    flex-wrap: wrap;
}

.updateStage-footer{
    display: flex;
    justify-content: space-around;
    width: 350px
}

.mainlist-stageTotals{
    display: flex;
    justify-content: space-around;
}

.numerator{
    color: var(--primary-color);
    font-size: .9em;
}

.mainlist-nextClaim{
    color: var(--secondary-color);
    font-weight: 500;
    font-size: 1.1em;
}

@media screen and (max-width: 700px) {
    
    .updateStage-footer{
        margin: 0;
    }
    .updateStage-footer > button{
        margin: 0;
        font-size: .8em;
    }
}