.poster {
    width: 100%;
    min-height: 40vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.backingImage {
    background-image: url('../../assets/service_construction.JPG');
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: brightness(40%);
    background-color: var(--primary-color);
}

.backingTint {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--highlight-color);
    opacity:14%;
}

.poster-header {
    position: relative;
    float: right;
    margin: 1em 1.5em;
    color: var(--bg-color);
    font-weight: 500;
    font-size: 2em;
    text-align: right;
}

.poster-text {
    margin: 0 40% 3em 2em;
    color: var(--bg-color);
    height: 100%;
    position: relative;
    
}

.services{
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1.5em 0;
    text-align: left;
}

.service{
    margin: 2em 0;
    width: 43%
}

.service > h2 {
    padding-top: .5em;
}

.service > p {
    color: var(--text-color);
}

.service-image {
    display: block;
    width: 100%;
    height: 17rem;
    object-fit: cover;
    background-color: var(--primary-color);
}

.pad {
    padding: 30px;
    text-align: center;
}

.pad > h1 {
    padding-top: 8vh;
}

.testimonial {
    background-color: var(--secondary-color);
    padding: 2em 30px;
    display: flex;
    justify-content: space-between;

}
.testimonial-text {
    margin-right: 1.5em;
    width: 40vw;
}

.testimonial-text > h2, .testimonial-text > h3, .testimonial-text > p{
    color: var(--bg-color);
    margin: .5em 0;
}

.testimonial-image {
    background-color: var(--primary-color);
    width: 40vw;
}

.sustainability {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    position: relative;
}

.sustainability-text {
    margin: 0;
    padding: 5vh;
    color: var(--bg-color);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.sustainability-text > h1, .sustainability-text > h2{
    font-weight: 400;
    margin: .5em;
}

.sustainability-text > .btn-green{
    min-width: 15em;
}

.sustainabilityImage {
    background-image: url('../../assets/kauri-forest.jpg');
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 30%;
}



/* @media screen and (max-width: 1200px) {
    .testimonial-image {
        height: 90%;
        width: 500px;
    }
} */


@media screen and (max-width: 700px) {
    .testimonial{
        flex-direction: column;
    }
    .services{
        padding: 1.5em 0;
    }
    .service{
        width: 100%;
    }
    .btn-green{
        width: 60%;
    }
    .testimonial-text{
        width: 100%;
    } 
    .testimonial-image{
        width: 100%;
    } 
}