
.progressbar {
  background-color: rgb(196, 196, 196);
  border-radius: 8px;
  width: 50%;
  height: 15px;
  margin: auto 1em;
  position: relative;
}

.progress-initial {
    border-radius: 8px;
    background-color: rgb(82, 82, 173);
    float: left;
    width: 0%;
    height: 100%;
    position: absolute;
  }

  .progress-warning {
    border-radius: 8px;
    border-bottom: 2px solid black;
    background-color: rgb(255, 205, 41);
    float: left;
    width: 0%;
    height: 80%;
    position: absolute;
  }

  .progress-progress {
    border-radius:8px;
    background-color:var(--primary-color);
    float: left;
    width: 0%;
    height: 100%;
    position: absolute;
  }