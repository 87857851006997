.project-financial-info {
  width: 100%;
  background-color: #ddd;
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 5px;
  padding: 1em;
}

.financialData {
  display: flex;
  justify-content: space-between;
  gap: 1em;
}

.financialData > .row-value{
  font-weight: 300;
  width: 7em;
  text-align: right;
}



@media screen and (max-width: 700px) {
  .project-financial-info {
    flex-direction: column;
    font-size: 1rem;
  }
  .financialData{
    width: 100%;
  }
}

