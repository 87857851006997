.page-title{
  color: var(--primary-color);
}

.create-form {
  max-width: 800px;
  background-color: #fff;
  border-radius: 5px;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.create-form > h2{
  align-self: center;
}
.create-form h3{
  text-align: center;
  margin-top: 2em;
}

.create-form label{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .5em;
  margin: .5em 0;
}

.create-form label > span{
  width: 30%;
}
.create-form label > div{
  width: 100%;
}

textarea{
  width: 100%;
  min-height: 8em;
}

.create-form span {
  margin: 0; 
}

.content-section > label{
  margin-top: 1em;
}

.add-btn{
  font-size: 1.5em;
  margin: 1em; 
}

.align-btn{
  text-align: center;
}

.assigned-staff{
  display: flex;
  flex-direction: column;
}

.assigned-staff > form > label{
  margin:  .5em 0;
  padding: 0 10% 0 7%;
}

.assigned-staff .btn{
  font-weight: 600;
  font-size: 1em;
}

.staffTable{
  width: 100%;
}


.staffTable > tr > th{
  text-align: left;
  
}

.staffMember{
  border: 1px solid lightgray;
  border-radius: 5px;
}
.staffMember > p, .staffMember > span{
  margin-left: .2em;
}

@media screen and (max-width: 700px) {
  .create-form {
    padding: 1em;
  }
}
