/* ////////////// ProjectClientInfoModel.css ///////////////// */
.modal {
  font-family: inherit;
  position: fixed;
  width: 95%;
  max-width: 750px;
  z-index: 1040;
  top: 1em;
  left: 1em;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  overflow: visible;
}

.backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
}

.modal-title {
  font-weight: 500;
  font-size: 1.25rem;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
}

.modal-desc,
.modal-header {
  padding: 1rem 1.3rem;
}

.calculatedAmount{
  color: green;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.close-button {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #888;
  border: none;
  background-color: white;
  opacity: 0.7;
  cursor: pointer;
}

button {
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  border: none;
  background-color: inherit;
  font-family: inherit;
  color: #888;
}

button:hover {
  color: var(--primary-color);
}

#btn_right{
  float: right;
}

.modal-footer {
  border-top: 1px solid #e9ecef;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2em;
}

.modal-example {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-label{
  min-width: 4em;
}

select, .inputSelector{
  padding: .5em;
  width: 100%;
  font-size: 1em;
  color: var(--heading-color);
  background-color: var;
}
@media screen and (max-width: 700px) {
  .modal {
    width: 90%; 
  }
  .modal-footer {
    justify-content: space-between;
    gap: 2em;
  }
}
