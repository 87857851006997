.nextClaim{
    background-color: var(--palegreen);
    border-radius: 5px;
    padding: 1em;
    margin-bottom: 2em;
    width: 700px;
    max-width: 85vw;
}

.nextClaim-header{
    margin-bottom: 1em;
}

.nextClaim-row{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--bg-color);
}

.nextClaim-input{
    display: flex;
    justify-content: space-between;
}

.nextClaim-comment{
    padding: 0;
    height: 2em;
    width: 80%;
}